import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { useState } from 'react'
import Button from 'components/core/button'
import TextInput from 'components/core/text-input/text-input'
import Modal from 'lib/components/modals/modal'
import { useToastContext } from 'providers/toast-provider'

const SaveSearch = () => {
  const [newSearchName, setNewSearchName] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { alert, notice } = useToastContext()
  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: () => {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete('page')
      const parameters = Object.fromEntries(queryParams.entries())
      return request('createSavedSearch', null, {
        body: {
          parameters,
          name: newSearchName,
        },
      })
    },
    onSuccess: () => {
      notice('Search has been saved')
      setIsModalOpen(false)
      setNewSearchName('')
      queryClient.invalidateQueries({ queryKey: ['savedSearches'] })
    },
    onError: () => alert('Search failed to save'),
  })

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Save Current Search</Button>
      <Modal open={isModalOpen} setOpen={setIsModalOpen}>
        <Modal.Header>Create Saved Search</Modal.Header>
        <TextInput name="name" value={newSearchName} onChange={(e) => setNewSearchName(e.target.value)} />
        <div className="tw-flex tw-gap-2">
          <Button onClick={() => createMutation.mutate()}>Save</Button>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
        </div>
      </Modal>
    </>
  )
}

export default SaveSearch
