import Button from 'components/core/button'
import { useAdminMediaContext } from 'components/pages/admin/admin-ticket/providers/admin-media-provider'
import { useAdminTicketContext } from 'components/pages/admin/admin-ticket/providers/admin-ticket-provider'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { generateAndDownloadZip } from 'lib/util/file/file'
import { filestackCdnUrl } from 'lib/util/filestack'
import { Download } from 'lucide-react'
import { useToastContext } from 'providers/toast-provider'

const DownloadButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    color="neutralGray"
    onClick={onClick}
    className="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-m-auto"
  >
    <Download className="lu-sm" /> <span>Download Assets</span>
  </Button>
)

function fileUrl(file: AdminTicketFile | TicketFile) {
  if (file.handle) {
    return `${filestackCdnUrl}/${file.handle}`
  }

  return file.previewUrl
}

export function DownloadAllAdminAssetsButton({ files }: { files: AdminTicketFile[] }) {
  const { ticket } = useAdminTicketContext()
  const { filters } = useAdminMediaContext()
  const { alert, notice } = useToastContext()

  async function handleDownloadAllAssets() {
    try {
      const filesMissingFromZip = files.filter(
        (file) => (file?.previewUrl?.includes('file_placeholder') || !file?.previewUrl) && !file.handle
      )

      const filesToBeZipped = files
        .filter(
          (file) =>
            !((file?.previewUrl?.includes('file_placeholder') && !file.handle) || !file.previewUrl) &&
            file.state !== AdminTicketFileState.Rejected &&
            !file.preview
        )
        .map((file) => ({ name: file.name, url: fileUrl(file) }))
      const creativeOrCustomer = filters.isUploadedByCreative ? 'creative' : 'customer'
      const versionName = filters.version === 0 ? 'all versions' : `version ${filters.version}`
      const zipName = `${creativeOrCustomer} #${ticket.id} ${versionName}`
      await generateAndDownloadZip(filesToBeZipped, zipName)

      if (filesMissingFromZip.length > 0) {
        alert('Some files could not be added to the zip file')
        console.error('Some files could not be added to the zip file', filesMissingFromZip)
      } else {
        notice('Assets downloaded successfully')
      }
    } catch (e) {
      alert('Error downloading assets')
      console.error('Error downloading assets', e)
    }
  }

  return <DownloadButton onClick={handleDownloadAllAssets} />
}

export function DownloadAllAssetsButton({ files, fileName }: { files: TicketFile[]; fileName: string }) {
  const { alert, notice } = useToastContext()

  async function handleDownloadAllAssets() {
    try {
      const filesMissingFromZip = files.filter(
        (file) => (file?.previewUrl?.includes('file_placeholder') || !file?.previewUrl) && !file.handle
      )

      const filesToBeZipped = files
        .filter(
          (file) =>
            !((file?.previewUrl?.includes('file_placeholder') && !file.handle) || !file.previewUrl) &&
            !file.preview &&
            file.uploadedByCreative
        )
        .map((file) => ({ name: file.name, url: fileUrl(file) }))
      await generateAndDownloadZip(filesToBeZipped, fileName)

      if (filesMissingFromZip.length > 0) {
        alert('Some files could not be added to the zip file')
        console.error('Some files could not be added to the zip file', filesMissingFromZip)
      } else {
        notice('Assets downloaded successfully')
      }
    } catch (e) {
      alert('Error downloading assets')
      console.error('Error downloading assets', e)
    }
  }

  return <DownloadButton onClick={handleDownloadAllAssets} />
}
