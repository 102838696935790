import React, { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Drawer } from 'lib/components/drawer/drawer'
import Button from 'components/core/button'
import { request, requestQuery } from 'lib/api/fetch-api'
import { ColumnFiltersState } from '@tanstack/react-table'
import { getFilters } from './use-table'
import { SavedSearch } from 'interfaces/saved-search'
import { useToastContext } from 'providers/toast-provider'

interface SavedSearchesProps {
  setFilters: (updater: ColumnFiltersState) => void
}

const SavedSearchesDrawer = ({ setFilters }: SavedSearchesProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()

  const { alert, notice } = useToastContext()
  const { data: savedSearches } = useQuery({ queryKey: ['savedSearches'], queryFn: requestQuery('getSavedSearches') })

  const onSavedSearchClick = (search: SavedSearch) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(search.parameters)
    url.search = searchParams.toString()
    setFilters(getFilters(url))
    setIsOpen(false)
  }

  const deleteMutation = useMutation({
    mutationFn: (id: string) => request('deleteSavedSearch', { id }),
    onSuccess: () => {
      notice('Saved search deleted')
      queryClient.invalidateQueries({ queryKey: ['savedSearches'] })
    },
    onError: () => alert('Error deleting saved search'),
  })

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>View Saved Searches</Button>
      <Drawer isOpen={isOpen} setOpen={setIsOpen} size="md">
        <ul>
          {savedSearches?.data?.map((search) => (
            <li onClick={() => onSavedSearchClick(search)} key={search.id}>
              {search.name}
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  deleteMutation.mutate(search.id)
                }}
              >
                Delete
              </Button>
            </li>
          ))}
        </ul>
      </Drawer>
    </>
  )
}

export default SavedSearchesDrawer
