import { MouseEvent, ReactElement, useMemo, useState } from 'react'
import { asTimeDuration, displayDate } from 'lib/util/date'
import { ClipboardList, Pencil, Quote, Send, Sparkles, Trash, X } from 'lucide-react'

import Checkbox from 'lib/components/checkbox/checkbox'
import { AdminTaskType, AdminTimelineItem } from 'lib/api/admin/timeline/admin-timeline'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import IconButton from 'lib/components/buttons/icon-button'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { capitalize } from 'lib/string/utils'
import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'

import Avatar from 'components/core/avatar'
import { useToastContext } from 'providers/toast-provider'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminAnnotationHighlightContext } from '../providers/admin-annotation-highlight-provider'
import { Annotation } from 'lib/api/annotations/annotations'
import { VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import { useAdminMediaContext } from '../providers/admin-media-provider'

const PLACEHOLDERS_FOLDER = 'file_placeholders'

const classNames = {
  article:
    'timeline-item tw-ml-2 tw-mb-4 tw-text-neutral-800 tw-rounded-lg tw-p-4 tw-max-w-xl tw-transition-colors tw-ease-in-out tw-duration-300 tw-break-words',
  body: 'tw-py-4 tw-whitespace-pre-wrap',
  error: 'tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-4 tw-mb-4 tw-text-center',
  form: {
    container: 'tw-flex tw-items-center tw-gap-2 tw-pb-4',
    textarea: 'tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-2',
  },
  header: {
    avatar: 'tw-shrink',
    byline: 'tw-flex-grow tw-text-left tw-flex',
    container: 'tw-flex tw-direction-row tw-justify-between tw-items-center tw-p-2 tw-gap-2',
  },
  li: 'tw-flex tw-items-center',
}

function TypeTitle({ type }: { type: string }): ReactElement {
  switch (type) {
    case 'copy':
      return (
        <>
          <Quote className="lu-light lu-md" /> EXACT COPY
        </>
      )
    case 'gen_ai_request':
      return (
        <>
          <Sparkles className="lu-light lu-md" /> AI REQUEST
        </>
      )
    case 'direction':
      return (
        <>
          <ClipboardList className="lu-light lu-md" /> GENERAL DIRECTION
        </>
      )
    default:
      return null
  }
}

interface TimelineItemProps {
  item: AdminTimelineItem
}

const getTimelineItemDisplayType = (item: AdminTimelineItem): string => {
  if (item.taskType === AdminTaskType.Annotation) {
    return 'annotation'
  }
  if (item.taskType === AdminTaskType.Conversation && item.private) {
    return 'note'
  }

  return 'message'
}

const isAnnotation = (item: AdminTimelineItem): boolean => {
  return item.taskType === AdminTaskType.Annotation
}

const isVideoAnnotation = (annotation: Annotation): boolean => {
  return !!(annotation?.data as VideoJSAnnotation)?.range
}

export default function AdminTimelineDisplayItem({ item }: TimelineItemProps): ReactElement {
  const [isLoadingCheck, setIsLoadingCheck] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [editedBodyValue, setEditedBodyValue] = useState(item.body)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { ticket } = useAdminTicketContext()
  const { updateTimelineItem, deleteTimelineItem, toggleCompletedStatus } = useAdminTimelineContext()
  const { clickedAnnotation, hoveredAnnotation, setHoveredAnnotation, setClickedAnnotation } =
    useAdminAnnotationHighlightContext()
  const { getFilePageNumber, getParentFileName } = useAdminMediaContext()
  const { alert, notice } = useToastContext()
  const displayItemType = getTimelineItemDisplayType(item)
  const checkDisabled = ticket.state !== AdminTicketState.inProgress || isLoadingCheck
  const isSubmittedByCustomer = ticket.submittedBy.id === item.user.id
  const showOverflowMenu = !isSubmittedByCustomer && (item.meta?.permissions?.update || item.meta?.permissions?.destroy)
  const containerId = item?.annotation?.id ? `timeline-item-${item?.annotation?.id}` : null

  const fileName = useMemo(() => {
    if (item?.annotation?.fileParentId) {
      return getParentFileName(item.annotation.fileParentId)
    }

    return item?.annotation?.fileName
  }, [getParentFileName, item?.annotation?.fileName, item?.annotation?.fileParentId])

  const fileCaption = useMemo(() => {
    if (isVideoAnnotation(item?.annotation)) {
      return asTimeDuration((item.annotation.data as VideoJSAnnotation).range.start)
    }

    if (item?.annotation?.fileParentId) {
      const pageNumber = getFilePageNumber(item.annotation.fileParentId, item.annotation.assetId)
      return `pg.${pageNumber}`
    }

    return null
  }, [getFilePageNumber, item?.annotation])

  async function handleCheckboxClick() {
    try {
      setIsLoadingCheck(true)
      await toggleCompletedStatus(item)
      setIsLoadingCheck(false)
      // Item has not been updated yet so we are doing the opposite here
      notice(`${capitalize(displayItemType)} has been marked as ${!item.completed ? 'complete' : 'incomplete'}`)
    } catch (error) {
      console.error(`Error marking ${displayItemType} as ${!item.completed ? 'complete' : 'incomplete'}`, error)
      alert(`Error marking ${displayItemType} as ${!item.completed ? 'complete' : 'incomplete'}`)
    }
  }

  function itemColorClass() {
    if (
      item.annotation &&
      (item.annotation?.id === hoveredAnnotation?.id || item.annotation?.id === clickedAnnotation?.id)
    ) {
      return 'tw-bg-cornflower-200'
    }

    if (item.draft) {
      return 'tw-border-neutral-300 tw-bg-neutral-100 tw-rounded-lg tw-border-dashed tw-border-2'
    } else if (isSubmittedByCustomer) {
      return 'tw-bg-cornflower-50'
    } else if (item.private) {
      return 'tw-bg-sunnyyellow-50'
    }
    return 'tw-bg-neutral-100'
  }

  function handleCancelClick() {
    setIsEditMode(false)
    setEditedBodyValue(item.body)
  }

  async function handleSaveClick() {
    try {
      await updateTimelineItem(item, editedBodyValue)
      setIsEditMode(false)
      notice(`${capitalize(displayItemType)} updated`)
    } catch (error) {
      console.error(`Error updating ${displayItemType}`, error)
      alert(`Error updating ${displayItemType}`)
    }
  }

  async function handleDeleteClick() {
    try {
      await deleteTimelineItem(item)
      setIsDeleteModalOpen(false)
      notice(`${capitalize(displayItemType)} deleted`)
    } catch (error) {
      console.error(`Error deleting ${displayItemType}`, error)
      alert(`Error deleting ${displayItemType}`)
    }
  }

  const thumbnailSource = item.annotation?.previewUrl.includes(PLACEHOLDERS_FOLDER)
    ? `/images/${item.annotation?.previewUrl}`
    : item.annotation?.previewUrl

  function handleMouseEnter(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    if (isAnnotation(item)) {
      setHoveredAnnotation(item.annotation)
    }
  }

  function handleMouseLeave(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    if (isAnnotation(item)) {
      setHoveredAnnotation(null)
    }
  }

  function handleClickedItem() {
    if (isAnnotation(item)) {
      setClickedAnnotation(item.annotation, item.ticketVersion)
    }
  }

  return (
    <li className={`${classNames.li} ${isSubmittedByCustomer ? 'tw-justify-start' : 'tw-justify-end'}`}>
      {item.taskType !== AdminTaskType.Conversation && (
        <Checkbox onClick={handleCheckboxClick} isChecked={item.completed} disabled={checkDisabled} />
      )}
      <article
        className={`${classNames.article} ${itemColorClass()}`}
        onClick={handleClickedItem}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id={containerId}
        data-testid="timeline-item"
      >
        <header className={classNames.header.container}>
          {item?.user?.avatar && (
            <div className={classNames.header.avatar}>
              <Avatar src={item.user.avatar} name={item.user.fullName} />
            </div>
          )}
          <div className={classNames.header.byline}>
            <div>{item.user?.fullName || ''}</div>
            <div className="tw-text-neutral-600 tw-pl-2">{displayDate(item.createdAt)}</div>
          </div>
          {showOverflowMenu && (
            <div className="tw-z-10">
              <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']} adjustedLeft>
                {item.meta?.permissions?.update && (
                  <IconFlyoutMenu.Button onClick={() => setIsEditMode(true)}>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <Pencil className="lu-light lu-md" />
                      Edit
                    </div>
                  </IconFlyoutMenu.Button>
                )}
                {item.meta.permissions.destroy && (
                  <IconFlyoutMenu.Button onClick={() => setIsDeleteModalOpen(true)}>
                    <div className="tw-flex tw-items-center tw-gap-2 tw-text-flushpink-500">
                      <Trash className="lu-light lu-md" />
                      Delete
                    </div>
                  </IconFlyoutMenu.Button>
                )}
              </IconFlyoutMenu>
            </div>
          )}
          {item.meta?.permissions?.destroy && (
            <ConfirmationModal
              cancelAction={() => setIsDeleteModalOpen(false)}
              confirmAction={handleDeleteClick}
              confirmBtnText="Yes, delete"
              message={<div>Are you sure you want to delete this {displayItemType}</div>}
              title="Delete Item"
              visible={isDeleteModalOpen}
            />
          )}
        </header>
        <div className={classNames.body}>
          <div className="tw-flex tw-items-center tw-mb-2">
            <TypeTitle type={item.taskType} />
            {item.taskType === AdminTaskType.Annotation && (
              <div className="tw-flex tw-items-center tw-gap-2" rel="noreferrer" data-testid="annotation-link">
                <img src={thumbnailSource} className="tw-w-5 tw-h-5" alt="thumbnail" />
                <div className="tw-text-neutral-800 tw-font-bold tw-break-all">{fileName}</div>
                <div className="tw-text-neutral-600" data-testid="annotation-file-caption">
                  {fileCaption}
                </div>
              </div>
            )}
          </div>
          {isEditMode ? (
            <>
              <WYSIWYGTextarea defaultValue={item.body} onChange={(v) => setEditedBodyValue(v)} />
              <div className="tw-flex tw-justify-end tw-items-center tw-gap-2 tw-w-full tw-pt-4">
                <IconButton color="secondary" onClick={handleCancelClick} dataTestid="cancel-update">
                  <X className="lu-light lu-sm" />
                </IconButton>
                <IconButton color="primary" onClick={handleSaveClick} dataTestid="submit-update">
                  <Send className="lu-light lu-sm" />
                </IconButton>
              </div>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: item.body }} />
          )}
        </div>
      </article>
    </li>
  )
}
