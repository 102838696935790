import { ReactElement, useMemo } from 'react'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminMediaItem from './admin-media-item'
import { AdminQaReviewStates } from 'lib/api/admin/tickets/admin-tickets'
import { displayDate } from 'lib/util/date'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { DownloadAllAdminAssetsButton } from 'lib/components/download-assets-button/download-assets-button'

interface AdminMediaListProps {
  files: AdminTicketFile[]
}

const classNames = {
  container: 'tw-flex tw-flex-col tw-gap-6 tw-items-center tw-pb-6',
  zeroState: 'tw-text-center tw-text-neutral-600 tw-text-sm tw-p-4',
}

export default function AdminMediaList({ files }: AdminMediaListProps): ReactElement {
  if (files.length === 0) {
    return <div className={classNames.zeroState}>No media files</div>
  }

  const areThereRejectedFiles = files.some((file) => file.state === AdminTicketFileState.Rejected)

  if (areThereRejectedFiles) {
    return <QaFailLabelWithFiles files={files} />
  }

  return (
    <div>
      <div className={classNames.container}>
        <DownloadAllAdminAssetsButton files={files} />
        {files.map((file) => {
          return <AdminMediaItem key={file.id} file={file} />
        })}
      </div>
    </div>
  )
}

function QaFailLabelWithFiles({ files }: { files: AdminTicketFile[] }) {
  const { ticket } = useAdminTicketContext()

  const rejectedFiles = useMemo(() => files.filter((file) => file.state === AdminTicketFileState.Rejected), [files])
  const nonRejectedFiles = useMemo(
    () => files.filter((file) => file.state !== AdminTicketFileState.Rejected && !file.preview),
    [files]
  )
  const previewFiles = useMemo(
    () => files.filter((file) => file.state !== AdminTicketFileState.Rejected && file.preview),
    [files]
  )

  const failedQRs = useMemo(
    () => ticket.qaReviews.filter((review) => review.state === AdminQaReviewStates.failed),
    [ticket.qaReviews]
  )

  const failedQRsWithFiles = useMemo(
    () =>
      failedQRs
        .map((review, i) => {
          const filesAssociatedWithReview = rejectedFiles.filter((file) => {
            const fileCreatedAt = new Date(file.createdAt)
            const reviewFinishedAt = new Date(review.finishedAt)

            if (i + 1 < failedQRs.length) {
              const nextReviewFinishedAt = new Date(failedQRs[i + 1].finishedAt)

              // Check if the file was created after the current review and before the next review
              return fileCreatedAt < reviewFinishedAt && fileCreatedAt > nextReviewFinishedAt
            }

            return fileCreatedAt < reviewFinishedAt
          })
          return {
            review: review,
            files: filesAssociatedWithReview,
          }
        })
        .filter((failedQRWithFiles) => failedQRWithFiles.files.length > 0),
    [failedQRs, rejectedFiles]
  )

  return (
    <div>
      <DownloadAllAdminAssetsButton files={nonRejectedFiles} />
      {nonRejectedFiles.length > 0 && (
        <div className="tw-pt-6">
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">DELIVERED ASSETS</div>
          <div className="tw-flex tw-flex-col tw-gap-6 tw-items-center">
            {nonRejectedFiles.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      )}
      {previewFiles.length > 0 && (
        <div>
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">PREVIEW ASSETS</div>
          <div className="tw-flex tw-flex-col tw-gap-6 tw-items-center">
            {previewFiles.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      )}
      {failedQRsWithFiles.map((failedQRWithFiles) => (
        <div key={failedQRWithFiles.review.id} data-testid={`failed-qr-${failedQRWithFiles.review.id}`}>
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">
            QR FAIL {displayDate(failedQRWithFiles.review.finishedAt)}
          </div>
          <div className="tw-flex tw-flex-col tw-gap-6 tw-items-center">
            {failedQRWithFiles.files.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
