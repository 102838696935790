import DropdownMenu from 'components/elements/dropdown-menu'
import ActiveTabMenu from 'components/elements/dropdown/menus/active-tab-menu'
import ArchivedTabMenu from 'components/elements/dropdown/menus/archived-tab-menu'
import DraftTabMenu from 'components/elements/dropdown/menus/draft-tab-menu'
import { SearchTicket } from 'interfaces/ticket'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
interface SearchOverflowMenuProps {
  ticket: SearchTicket
}

const SearchOverflowMenu = ({ ticket }: SearchOverflowMenuProps) => {
  const {
    ticket_id,
    title,
    // filesDownloadUrl,
    // friendlyStatusName, waiting for matt to add these to kendra
    ticket_created_by: user,
    assets,
  } = ticket

  const filesDownloadUrl = ''
  const friendlyStatusName = 'active' as string

  const id = BigInt(ticket_id)
  const ticketFilesCount = BigInt(assets?.length || 0)

  const { menuButtonAction } = useConfirmationModalContext()

  return (
    <DropdownMenu>
      {friendlyStatusName === DesignRequestStatus.draft && (
        <DraftTabMenu
          requestId={id}
          url={`tickets/${id}`}
          subject={title}
          userId={user.id}
          menuActionBtn={menuButtonAction}
        />
      )}
      {friendlyStatusName === DesignRequestStatus.archived && (
        <ArchivedTabMenu
          id={id}
          filesDownloadUrl={filesDownloadUrl}
          menuActionBtn={menuButtonAction}
          status={friendlyStatusName}
          subject={title}
          ticketFilesCount={ticketFilesCount}
          userId={user?.id}
        />
      )}
      {friendlyStatusName !== DesignRequestStatus.draft && friendlyStatusName !== DesignRequestStatus.archived && (
        <ActiveTabMenu
          id={BigInt(id)}
          subject={title}
          filesDownloadUrl={filesDownloadUrl}
          status={friendlyStatusName}
          userId={user?.id}
          menuActionBtn={menuButtonAction}
          ticketFilesCount={ticketFilesCount}
        />
      )}
    </DropdownMenu>
  )
}

export default SearchOverflowMenu
